@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto Sans:wght@300;600&family=Nanum+Pen+Script&family=Teko:wght@300;400;500&display=swap'); */

@font-face {
  font-family: 'Accumin Pro Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/acumin-pro-condensed-black.woff2') format('woff2');
}

@font-face {
  font-family: 'Accumin Pro Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./fonts/acumin-pro-condensed-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Accumin Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/acumin-pro-medium.woff2') format('woff2');
}


/* devanagari */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfsCVgqHAtXN_nWQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfsCpgqHAtXN_nWQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfsCRgqHAtXN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBesCVgqHAtXN_nWQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBesCpgqHAtXN_nWQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBesCRgqHAtXN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gfaJ9pQlCpVoXVQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gfa19pQlCpVoXVQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gfaN9pQlCpVo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: white;
  font-family: 'Accumin Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.exiled_h1 {
  font-size: 8rem;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1rem;
  line-height: 100px;
}

.exiled_h2 {
  font-size: 4rem;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3rem;
  line-height: 54px;
}

.exiled_h3 {
  font-size: 40px;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 40px;
}

@media only screen and (max-width: 900px) {
  .exiled_h3 {
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    line-height: 40px;
  }
}

.exiled_h4 {
  font-size: 1.8rem;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 35px;
}

.exiled_h5 {
  font-size: 1.6rem;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  line-height: 22px;
}

.exiled_p {
  font-size: 12pt;
  font-family: "Noto Sans", sans-serif;
  text-transform: uppercase;
  line-height: 22px;
  padding-bottom: 22px;
}

.ae_logo_bg {
  background: url('./img/exiled/Logo_Animation_blackBG.gif');
  background-size: 64px, 64px;
  background-repeat: no-repeat;
  background-position-x: 24px;
  background-position-y: 24px;
  background-attachment: fixed;
}
@media only screen and (max-width: 1280px) {
  .ae_logo_bg {
    /* background: url('./img/exiled/Logo_Animation_blackBG.gif');
    background-size: 64px, 64px;
    background-repeat: no-repeat;
    background-position-x: 24px;
    background-position-y: 24px;
    background-attachment: fixed; */
    background: none;
  }
}

#member_bg {
  background: url('./img/ae-pattern.png');
  background-size: 205px;
  background-position-y: center;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  font-size: 80pt;
  font-family: "Accumin Pro Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -2px;
  line-height: 80pt;
}

h2 {
  font-size: 50pt;
  font-family: "Accumin Pro Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -2px;
  line-height: 50pt;
}

h3 {
  font-size: 30pt;
  font-family: "Accumin Pro Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30pt;
}

h3 a:hover {
  text-decoration: none !important;
}

h4 {
  font-size: 26pt;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 26pt;
}

h5 {
  font-size: 16pt;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 16pt;
}

p {
  font-size: 12pt;
  font-family: "Accumin Pro", sans-serif;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 25px;
}

.nav-btn {
  font-size: 15pt;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 15pt;
}

.std_btn {
  font-size: 14pt;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 10pt;
  padding: 12px 32px 10px 32px;
}

@media only screen and (max-width: 768px) {
  .std_btn {
    font-size: 14pt;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 500;
    line-height: 10pt;
    padding: 12px 16px 10px 16px;
  }
}

.std_btn_no_y_pad {
  font-size: 14pt;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 10pt;
}

@media only screen and (max-width: 768px) {
  .std_btn_no_y_pad {
    font-size: 14pt;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 500;
    line-height: 10pt;
    /* padding: 0px; */
  }
}

.blk_btn {
  background-color: #ffffff;
  border: 2px black solid;
  color: #000000;
  font-family: "Teko", sans-serif;
  font-weight: 600;
  font-size: 22px;
  padding: 8px 14px 6px 14px;
  transition: 0.1s;
}

.blk_btn:hover {
  background-color: #000000;
  color: #ffffff;
  font-family: "Teko", sans-serif;
  font-weight: 600;
  font-size: 22px;
  padding: 8px 14px 6px 14px;
}

a {
  color: #F06969;
  /* text-decoration: underline; */
}

a:hover {
  text-decoration: underline;
}

code {
  font-size: 17px;
  padding: 5px 5px 5px 5px;
  background-color: #f9f8fe;
}

.bebas {
  font-family: "Bebas Neue", sans-serif;
  text-decoration: none !important;
}

.logo a {
  color: red;
  text-decoration: none !important;
}

#jumbobg {
  background: url("./img/ae_phase_two_poster.jpg");
  background-position: 0% 0%;
}

#page-banner {
  background: url("./img/v2_assets/classroom_graphic.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

#playground-bg {
  background: url("./img/v2_assets/SwingAlphaBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
}

#playground-bg-landing {
  background: url("./img/v2_assets/SwingAlphaBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
}

@media only screen and (max-width: 768px) {
  #playground-bg-landing {
    background: url("./img/v2_assets/SwingAlphaBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}

@media only screen and (max-width: 1500px) {
  #jumbobg {
    background: url("./img/clouds-right.png"), url("./img/jungle_gym.png"),
      linear-gradient(
        5deg,
        rgba(227, 190, 187, 1) 0%,
        rgba(221, 159, 133, 1) 18%,
        rgba(142, 133, 200, 1) 81%
      );
    background-repeat: no-repeat;
    background-position: 100% 23%, 100% -200%;
  }
}

#classroom {
  background: url("./img/class_agenda/pencils.png"),
    url("./img/class_agenda/scribble-pg.png"),
    url("./img/class_agenda/sketch-pg.png"), #d6b69f;
  background-repeat: no-repeat;
  background-size: 25%, 60%, 35%;
  background-position: 100% -6%, -5% 100%, 100% 100%;
}

@media only screen and (max-width: 768px) {
  #classroom {
    background: url("./img/class_agenda/pencils.png"),
      url("./img/class_agenda/sketch-pg.png"),
      url("./img/class_agenda/scribble-pg.png"), #d6b69f;
    background-repeat: no-repeat;
    background-size: 40%, 65%, 70%;
    background-position: 100% 0%, 70% 2%, 5% 0%;
  }
}

@media only screen and (min-width: 1600px) {
  #classroom {
    background: url("./img/class_agenda/pencils.png"),
      url("./img/class_agenda/scribble-pg.png"),
      url("./img/class_agenda/sketch-pg.png"), #d6b69f;
    background-repeat: no-repeat;
    background-size: 25%, 35%, 22%;
    background-position: 100% -14%, 5% 100%, 95% 100%;
  }
}

#paper {
  background: url("./img/class_agenda/paper-frame.png");
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: 50% 0%;
}

.ruler {
  width: 100%;
  border-bottom: 1px solid #57afbf;
}

/*
#mintBg {
  background: url("./img/soldout.png"), url("./img/clouds-left.png"),
    url("./img/clouds-right.png"), url("./img/jungle_gym.png"),
    linear-gradient(
      5deg,
      rgba(227, 190, 187, 1) 0%,
      rgba(221, 159, 133, 1) 18%,
      rgba(142, 133, 200, 1) 81%
    );
  background-repeat: no-repeat;
  background-size: 60%, 45%, 45%, 70%, 100%;
  background-position: 50% 100%, -10% 12%, 100% 16%, 170% 180%;
}
*/

#mintBg {
  background: url("./img/soldout.png"), url("./img/theschoolyard.jpeg");
  background-repeat: no-repeat;
  background-size: 60%, cover;
  background-position: 50% 100%, 50% 50%;
}

@media only screen and (max-width: 1200px) {
  #mintBg {
    background: url("./img/soldout.png"), url("./img/theschoolyard.jpeg");
    background-repeat: no-repeat;
    background-size: 100%, cover;
    background-position: 0% 100%, 50% 50%;
  }
}
#collectionBg {
  background: url("./img/clouds-left.png"), url("./img/clouds-right.png"),
    linear-gradient(
      5deg,
      rgba(206, 153, 10, 1) 0%,
      rgba(189, 101, 10, 1) 18%,
      rgba(0, 0, 0, 1) 91%
    );
  background-repeat: no-repeat;
  background-size: 45%, 45%, 100%;
  background-position: -10% 12%, 100% 16%;
}
/* 
@media only screen and (max-width: 1500px) {
  #collectionBg {
    background: url("./img/clouds-right.png"),
      linear-gradient(
        5deg,
        rgba(206, 153, 10, 1) 0%,
        rgba(189, 101, 10, 1) 18%,
        rgba(0, 0, 0, 1) 91%
      );
    background-repeat: no-repeat;
    background-size: 70%, 100%;
    background-position: 100% 23%;
  }
} */

@media only screen and (min-width: 1500px) {
  #show_and_tell_bg {
    background: url("./img/show_and_tell/kid_with_backpack.jpg");
    background-repeat: no-repeat;
    background-size: 18%;
    background-position-x: right;
    background-position-y: bottom;
  }
}

@media only screen and (max-width: 1500px) {
  #show_and_tell_bg {
    background: url("./img/show_and_tell/kid_with_backpack.jpg");
    background-repeat: no-repeat;
    background-size: 22%;
    background-position-x: 110%;
    background-position-y: bottom;
  }
}

  #butterfly_mob {
    background: url("https://alphaelem.s3.amazonaws.com/backpack-teaser.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: 120%;
    background-color: #000000;
  }

  @media only screen and (max-width: 500px) {
    #butterfly_mob {
      background: url("https://alphaelem.s3.amazonaws.com/backpack-teaser.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-x: center;
      background-position-y: 100%;
      background-color: #000000;
    }
  }



@media only screen and (max-width: 1200px) {
  #butterfly {
    /* background: url("./img/show_and_tell/web-butterfly.png"); */
    background: url("https://alphaelem.s3.amazonaws.com/backpack-teaser.jpg");
    background-repeat: no-repeat;
    background-size: 42%;
    background-position-x: 100%;
    background-position-y: 30%;
    background-color: #000000;
  }
}
@media only screen and (min-width: 2200px) {
  #butterfly {
    /* background: url("./img/show_and_tell/web-butterfly.png"); */
    background: url("https://alphaelem.s3.amazonaws.com/backpack-teaser.jpg");
    background-repeat: no-repeat;
    background-size: 30%;
    background-position-x: 75%;
    background-position-y: 32%;
    background-color: #000000;
  }
}
/* @media only screen and (max-width: 1500px) {
  #show_and_tell_bg {
    background: url("./img/clouds-right.png"),
      linear-gradient(
        5deg,
        rgba(206, 153, 10, 1) 0%,
        rgba(189, 101, 10, 1) 18%,
        rgba(0, 0, 0, 1) 91%
      );
    background-repeat: no-repeat;
    background-size: 70%, 100%;
    background-position: 100% 23%;
  }
} */
input[type="checkbox"] {
  border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

#walletconnect-qrcode-text {
  font-family: ui-rounded, "SF Pro Rounded", "SF Pro Text",
    medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont,
    ui-sans-serif, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  padding-bottom: 0px;
}

.walletconnect-modal__header p {
  font-family: ui-rounded, "SF Pro Rounded", "SF Pro Text",
    medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont,
    ui-sans-serif, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  padding-bottom: 0px;
}

.walletconnect-modal__mobile__toggle a {
  color: #000000 !important;
}

.busloads {
  width: 100%;
  background: url("./img/schoolbus.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#artclass {
  /* background-color: #212121; */
  background: url("./img/v2_assets/pencils.png"),
    url("./img/v2_assets/trash-paper.png"),
    url("./img/v2_assets/sketch-alpha.png"), #212121;
  background-repeat: no-repeat;
  background-size: 25%, 25%, 36%;
  background-position: 0% 0%, 100% 100%, 0% 100%;
}

@media only screen and (min-width: 2000px) {
  #artclass {
    background: url("./img/v2_assets/pencils.png"),
    url("./img/v2_assets/trash-paper.png"),
    url("./img/v2_assets/sketch-alpha.png"), #212121;
    background-repeat: no-repeat;
    background-size: 19%, 20%, 24%;
    background-position: 0% 0%, 100% 100%, 0% 100%;
  }
}

/* .accordion-item {
  border: 2px solid #093040;
} */

/* import paperFrame from './img/class_agenda/paper-frame.png';
import pencils from './img/class_agenda/pencils.png';
import sketchLeft from './img/class_agenda/scribble-pg.png';
import sketchRight from './img/class_agenda/sketch-pg.png'; */


#bomb_boy {
  background: url("./img/bomb_boy_promo/promo-cross.svg");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position-x: 0%;
  background-position-y: 40%;
  background-color: #ffffff;
}

@media only screen and (max-width: 780px) {
  #bomb_boy {
    background: url("./img/bomb_boy_promo/promo-cross.svg");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position-x: center;
    background-position-y: 0%;
    background-color: #ffffff;
  }
}

